import { ArticlePreviewFragment } from "@gql-schema";
import { AnalyticsService } from "@shared/services/AnalyticsService";
import { UrlHelper } from "@shared/utils/UrlHelper";
import { BlogItem, BlogItemProps } from "@ui/components/BlogItem/BlogItem";
import { Box, BoxProps } from "@ui/components/Box/Box";
import { ButtonGreen } from "@ui/components/Button/ButtonGreen";
import { ButtonOutlinedGreen } from "@ui/components/Button/ButtonOutlinedGreen";
import { FlexBox } from "@ui/components/FlexBox/FlexBox";
import { Grid } from "@ui/components/Grid/Grid";
import { Icon } from "@ui/components/Icon/Icon";
import { TypoH2 } from "@ui/components/Typography/TypoH2";
import { BREAKPOINTS } from "@ui/theme/breakpoints";
import { BUTTONS } from "@ui/theme/buttons";
import { COLORS } from "@ui/theme/colors";
import { CONTAINERS } from "@ui/theme/containers";
import { useWindowSize } from "@uxf/core/hooks/useWindowSize";
import { FC, PropsWithChildren, ReactNode, memo, useCallback, useMemo } from "react";

interface Props extends Omit<BoxProps, "title"> {
    blogArticles?: ArticlePreviewFragment[];
    buttonText?: string;
    showButton?: boolean;
    title?: ReactNode;
}

const Component: FC<PropsWithChildren<Props>> = (props) => {
    const {
        blogArticles,
        buttonText = "Všechny novinky",
        showButton = true,
        title = <>Novinky z&nbsp;Kalkulátor.cz</>,
        ...restProps
    } = props;
    const windowSize = useWindowSize();

    const getResponsiveArticlesCount = useCallback((): number => {
        const windowWidth = windowSize?.width;
        if (windowWidth && windowWidth >= BREAKPOINTS.md && windowWidth < BREAKPOINTS.lg) {
            return 2;
        }
        return 3;
    }, [windowSize?.width]);

    const articles = useMemo(() => {
        const articleArray: BlogItemProps[] = [];
        if (blogArticles) {
            blogArticles.forEach((article) => {
                articleArray.push({
                    id: `${article.id}`,
                    title: article.title || "",
                    perex: article.perex || "",
                    image: article.image ? UrlHelper.getImageUrl(article.image, 300, 200) : undefined,
                    route: "blog-zone/article",
                    params: { articleId: article.id, slug: article.seoName },
                });
            });
        }

        return articleArray.slice(0, getResponsiveArticlesCount());
    }, [blogArticles, getResponsiveArticlesCount]);

    return (
        <Box as="section" $pt={[28, null, 62, 74]} $pb={[38, null, 54, 98]} $px={[24, null, 0]} {...restProps}>
            <TypoH2 $color={COLORS.green.default} $textAlign="center">
                {title}
            </TypoH2>
            <Grid
                $display={["block", null, "grid"]}
                $gridColumnCount={[null, null, 2, 3]}
                $gridGap={[null, null, 30, 48]}
                $maxWidth={Object.values(CONTAINERS)}
                $mt={[44, null, 56, 76]}
                $mx="auto"
                role="list"
            >
                {articles.map((item) => (
                    <BlogItem
                        key={item.id}
                        role="listitem"
                        {...item}
                        analyticsCallback={AnalyticsService.uiEvent("Button", "Click", "HP_Clanky")}
                    />
                ))}
            </Grid>
            {showButton && (
                <FlexBox $justifyContent="center" $mt={[52, null, 80]}>
                    <Box $hidden={{ to: "md" }}>
                        <ButtonGreen route="blog-zone/news" $px={56}>
                            {buttonText}
                        </ButtonGreen>
                    </Box>
                    <Box $hidden={{ from: "md" }}>
                        <ButtonOutlinedGreen
                            $height={BUTTONS.medium}
                            endIcon={<Icon name="arrowRightCircleOutline" size={24} $ml={24} />}
                            route="blog-zone/news"
                        >
                            {buttonText}
                        </ButtonOutlinedGreen>
                    </Box>
                </FlexBox>
            )}
        </Box>
    );
};

export const BlogBox = memo(Component);

BlogBox.displayName = "BlogBox";
