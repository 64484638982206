import { Clickable, ClickableProps } from "@ui/components/Clickable/Clickable";
import { focusShadow } from "@ui/mixins/focusShadow";
import { BREAKPOINTS } from "@ui/theme/breakpoints";
import { COLORS } from "@ui/theme/colors";
import { SHADOWS } from "@ui/theme/shadows";
import { CLASSES } from "@uxf/core/constants/classes";
import { Margins, margins } from "@uxf/styles/properties/margins";
import { rem } from "@uxf/styles/utils/rem";
import { mqMin } from "@uxf/styles/utils/responsive";
import styled, { css } from "styled-components";

export interface BlogItemRootProps extends ClickableProps, Margins {}

export const Root = styled(Clickable)<BlogItemRootProps>(({ $mb, $ml, $mr, $mt, $mx, $my }) => {
    return css`
        box-shadow: ${SHADOWS.shadow2};
        color: ${COLORS.green.default};
        display: flex;
        overflow: hidden;
        text-decoration: none;
        background-color: ${COLORS.neutral["100"]};
        border-radius: ${rem(30)};

        ${mqMin(BREAKPOINTS.md)} {
            background-color: ${COLORS.white};
            border-radius: ${rem(40)};
        }

        ${margins({ breakpoints: BREAKPOINTS, $mb, $ml, $mr, $mt, $mx, $my })};

        &:nth-child(2) {
            ${mqMin(BREAKPOINTS.lg)} {
                transform: scale(1.1);
            }
        }

        &:focus {
            outline: none;
        }

        &.${CLASSES.FOCUS_VISIBLE} {
            ${focusShadow(COLORS.green.default)};
        }
    `;
});
