import { BoxProps } from "@ui/components/Box/Box";
import { ScrollElementContext } from "@ui/contexts/scrollElementContext";
import { BREAKPOINTS } from "@ui/theme/breakpoints";
import { useClickableProps } from "@uxf/core/hooks/useClickableProps";
import { useMinWindowWidth } from "@uxf/core/hooks/useMinWindowWidth";
import { FC, MouseEvent, PropsWithChildren, useCallback, useContext, useRef } from "react";
import { CollapsibleRoot, CollapsibleRootProps } from "./styles/CollapsibleRoot";

export interface CollapsibleProps extends Omit<BoxProps, "title"> {
    analyticsCallback?: () => void;
    disableScrollOnExpanded?: boolean;
    focusColor?: CollapsibleRootProps["$focusColor"];
    isExpanded?: boolean;
    onToggle?: () => void;
}

export const Collapsible: FC<PropsWithChildren<CollapsibleProps>> = (props) => {
    const scrollRef = useRef<HTMLDivElement>(null);
    const [scrollElement] = useContext(ScrollElementContext);
    const isDesktop = useMinWindowWidth(BREAKPOINTS.md);

    const {
        analyticsCallback,
        children,
        disableScrollOnExpanded,
        isExpanded,
        onClick,
        onKeyDown,
        onKeyUp,
        onToggle,
        ...restProps
    } = props;

    const handleClick = useCallback(
        (e: MouseEvent<HTMLDivElement>) => {
            if (onClick) {
                onClick(e);
            }
            if (onToggle) {
                onToggle();
            }

            const node = scrollRef.current;

            if (
                !disableScrollOnExpanded &&
                node?.contains(e.currentTarget) &&
                !isExpanded &&
                !isDesktop &&
                scrollElement
            ) {
                setTimeout(() => {
                    scrollElement.scrollTo({
                        top: node.offsetTop,
                        left: 0,
                        behavior: "smooth",
                    });
                }, 200);
            }
        },
        [onClick, onToggle, disableScrollOnExpanded, isExpanded, isDesktop, scrollElement],
    );

    const clickableProps = useClickableProps<BoxProps>({
        $overflow: "hidden",
        "aria-current": isExpanded ? true : undefined,
        "aria-expanded": isExpanded,
        analyticsCallback,
        disabled: !(onClick || onToggle),
        onKeyDown,
        onKeyUp,
        onClick: handleClick,
        ...restProps,
    });

    return (
        <CollapsibleRoot ref={scrollRef} {...clickableProps}>
            {children}
        </CollapsibleRoot>
    );
};
