import { BREAKPOINTS, Breakpoints } from "@ui/theme/breakpoints";
import { TYPOGRAPHY } from "@ui/theme/typography";
import { BlockTextElements, InlineElements } from "@ui/types/elements";
import { injectCss } from "@uxf/styles/mixins/injectCss";
import { responsiveHidden } from "@uxf/styles/mixins/responsiveHidden";
import { responsiveHideVisually } from "@uxf/styles/mixins/responsiveHideVisually";
import { responsiveNoWrap } from "@uxf/styles/mixins/responsiveNoWrap";
import { responsiveTruncate } from "@uxf/styles/mixins/responsiveTruncate";
import { Character } from "@uxf/styles/properties/character";
import { Colors } from "@uxf/styles/properties/colors";
import { Margins } from "@uxf/styles/properties/margins";
import { maxLines } from "@uxf/styles/properties/maxLines";
import { Paragraph } from "@uxf/styles/properties/paragraph";
import { Visibility } from "@uxf/styles/properties/visibility";
import { em } from "@uxf/styles/utils/em";
import { formatResponsiveValue } from "@uxf/styles/utils/formatValue";
import { resolveResponsiveProperties } from "@uxf/styles/utils/responsive";
import { HTMLAttributes } from "react";
import styled, { FlattenSimpleInterpolation, css } from "styled-components";

interface TypoH2
    extends Omit<Character, "$fontFamily" | "$fontStyle" | "$fontStretch">,
        Omit<Paragraph<Breakpoints>, "$hyphens">,
        Margins,
        Omit<Colors, "$backgroundColor">,
        Omit<Visibility<Breakpoints>, "$backfaceVisibility">,
        HTMLAttributes<HTMLHeadingElement> {
    as?: keyof Pick<JSX.IntrinsicElements, BlockTextElements | InlineElements>;
    $css?: FlattenSimpleInterpolation;
}

export const TypoH2 = styled("h2")<TypoH2>(
    ({
        $color = "inherit",
        $css,
        $fontSize = [28, null, 32, null, 36],
        $fontWeight = TYPOGRAPHY.fontWeight.bold,
        $hidden,
        $hideVisually,
        $letterSpacing = em(0.1),
        $lineHeight = (44 / 36).toString(),
        $maxLines,
        $mb,
        $ml,
        $mr,
        $mt,
        $mx,
        $my,
        $noWrap,
        $opacity,
        $textAlign,
        $textIndent,
        $textTransform,
        $truncate,
    }) => css`
        font-style: ${TYPOGRAPHY.fontStyle.normal};

        ${resolveResponsiveProperties(BREAKPOINTS, {
            color: formatResponsiveValue($color),
            fontSize: formatResponsiveValue($fontSize),
            fontWeight: formatResponsiveValue($fontWeight, true),
            letterSpacing: formatResponsiveValue($letterSpacing),
            lineHeight: formatResponsiveValue($lineHeight),
            marginBottom: formatResponsiveValue($mb ?? $my),
            marginLeft: formatResponsiveValue($ml ?? $mx),
            marginRight: formatResponsiveValue($mr ?? $mx),
            marginTop: formatResponsiveValue($mt ?? $my),
            opacity: formatResponsiveValue($opacity, true),
            textAlign: formatResponsiveValue($textAlign),
            textIndent: formatResponsiveValue($textIndent),
            textTransform: formatResponsiveValue($textTransform),
        })};

        ${maxLines({ breakpoints: BREAKPOINTS, $maxLines })};
        ${responsiveTruncate(BREAKPOINTS, $truncate)};
        ${responsiveNoWrap(BREAKPOINTS, $noWrap)};

        ${responsiveHideVisually(BREAKPOINTS, $hideVisually)};
        ${responsiveHidden(BREAKPOINTS, $hidden)};

        ${injectCss($css)};
    `,
);
