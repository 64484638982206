import { Box, BoxProps } from "@ui/components/Box/Box";
import { focusShadow } from "@ui/mixins/focusShadow";
import { COLORS } from "@ui/theme/colors";
import { CLASSES } from "@uxf/core/constants/classes";
import { ColorProperty } from "@uxf/styles/types";
import styled, { css } from "styled-components";

export interface CollapsibleRootProps extends BoxProps {
    $focusColor?: ColorProperty;
}

export const CollapsibleRoot = styled(Box)<CollapsibleRootProps>(
    ({ $focusColor = COLORS.green.default }) => css`
        cursor: pointer;

        &:focus {
            outline-style: none;
        }

        &.${CLASSES.FOCUS_VISIBLE} {
            ${focusShadow($focusColor)};
        }
    `,
);
