import { CLASSES } from "@uxf/core/constants/classes";
import { transition } from "@uxf/styles/utils/transition";
import { css } from "styled-components";

export const chevronStyle = css`
    overflow: hidden;
    transform: rotate(0deg);
    transition: ${transition("transform")};

    &.${CLASSES.IS_ACTIVE} {
        transform: rotate(90deg);
    }
`;
