import { Box } from "@ui/components/Box/Box";
import { FlexBox } from "@ui/components/FlexBox/FlexBox";
import { Image } from "@ui/components/Image/Image";
import { BodyText2 } from "@ui/components/Typography/BodyText2";
import { TYPOGRAPHY } from "@ui/theme/typography";
import { FC, memo, PropsWithChildren, ReactNode } from "react";
import { css } from "styled-components";
import { BlogItemShapes } from "./BlogItemShapes";
import { BlogItemRootProps, Root } from "./styles/Root";

export interface BlogItemProps extends Omit<BlogItemRootProps, "title"> {
    image?: string | null;
    title: string;
    perex: ReactNode;
}

const BlogItemComponent: FC<PropsWithChildren<BlogItemProps>> = (props) => {
    const { image, title, perex, ...restProps } = props;

    return (
        <Root $mt={[28, null, 0]} {...restProps}>
            <FlexBox $width="100%" $flexDirection={["row", null, "column"]}>
                <Image
                    $height={[96, null, 200]}
                    $width={["30%", null, "100%"]}
                    $flexShrink={0}
                    src={image}
                    imgProps={{ alt: title }}
                />
                <BlogItemShapes />
                <FlexBox
                    $flexDirection="column"
                    $pr={24}
                    $pl={[8, null, 24]}
                    $pt={[8, null, 24]}
                    $pb={[8, null, 36]}
                    $justifyContent={["center", null, "flex-start"]}
                >
                    {!!title && (
                        <Box $minHeight={[null, null, 44]}>
                            <BodyText2
                                as="h3"
                                $css={css`
                                    text-decoration: underline;
                                `}
                                $maxLines={2}
                                $fontWeight={[TYPOGRAPHY.fontWeight.semiBold, null, TYPOGRAPHY.fontWeight.bold]}
                                $fontSize={[12, null, 14]}
                            >
                                {title}
                            </BodyText2>
                        </Box>
                    )}
                    <FlexBox $mt={24} $hidden={{ to: "md" }}>
                        <p className="ui-typo-small line-clamp-3 font-normal">{perex}</p>
                    </FlexBox>
                </FlexBox>
            </FlexBox>
        </Root>
    );
};

export const BlogItem = memo(BlogItemComponent);

BlogItem.displayName = "BlogItem";
