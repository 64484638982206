import { Box, type BoxProps } from "@ui/components/Box/Box";
import { ButtonOpaque } from "@ui/components/Button/ButtonOpaque";
import { ButtonYellow } from "@ui/components/Button/ButtonYellow";
import { CollapsibleFaq } from "@ui/components/Collapsible/CollapsibleFaq";
import { Icon } from "@ui/components/Icon/Icon";
import { BUTTONS } from "@ui/theme/buttons";
import { COLORS } from "@ui/theme/colors";
import { FC, PropsWithChildren, ReactNode, memo, useState, type MouseEventHandler } from "react";

export interface FaqAccordionItemData {
    id: number;
    title: ReactNode;
    text: ReactNode;
    seoName?: string;
}

interface Props extends BoxProps {
    data?: FaqAccordionItemData[];
    invert?: boolean;
    onClickRedirect?: (item: FaqAccordionItemData) => void;
}

const VISIBLE_QUESTIONS_COUNT = 4;

const Component: FC<PropsWithChildren<Props>> = (props) => {
    const { data, invert, id, onClickRedirect, ...restProps } = props;

    const [activeItemIndex, setActiveItemIndex] = useState("");
    const [showAllQuestions, setShowAllQuestions] = useState(false);

    const handleFaqClick =
        (item: FaqAccordionItemData): MouseEventHandler =>
        () => {
            if (onClickRedirect && !item.text) {
                onClickRedirect(item);
            }
        };

    const onToggle = (item: FaqAccordionItemData, index: string) => () => {
        if (item.text) {
            if (activeItemIndex !== index) {
                setActiveItemIndex(index);
            }
            if (activeItemIndex === index) {
                setActiveItemIndex("");
            }
        }
    };

    const loadAllHandler: MouseEventHandler = () => {
        setShowAllQuestions(true);
    };

    const totalQuestionsCount = data?.length ?? 0;

    if (!data) {
        return null;
    }

    const ShowMoreButtonComponent = invert ? ButtonYellow : ButtonOpaque;

    return (
        <>
            <Box role="list" {...restProps}>
                <div className="column-gap-8 md:columns-2">
                    {data.map((item, index) => (
                        <CollapsibleFaq
                            role="listitem"
                            headingElement="p"
                            invert={invert}
                            isExpanded={item.text ? activeItemIndex === `${id}-faq1-${index}` : false}
                            key={item.id}
                            onClick={handleFaqClick(item)}
                            onToggle={onToggle(item, `${id}-faq1-${index}`)}
                            text={item.text}
                            title={item.title}
                            className={
                                !showAllQuestions && index >= VISIBLE_QUESTIONS_COUNT ? "max-md:hidden" : undefined
                            }
                        />
                    ))}
                </div>
            </Box>
            {!showAllQuestions && totalQuestionsCount > VISIBLE_QUESTIONS_COUNT && (
                <div className="mt-9 text-center md:hidden">
                    <ShowMoreButtonComponent
                        $fontSize={[14, null, 16]}
                        $py={8}
                        $textPr={12}
                        $textTransform="uppercase"
                        endIcon={<Icon name="arrowRightCircleOutline" size={20} $color={COLORS.green.default} />}
                        onClick={loadAllHandler}
                        $height={BUTTONS.small}
                    >
                        Zobrazit více
                    </ShowMoreButtonComponent>
                </div>
            )}
        </>
    );
};

export const FaqAccordion = memo(Component);

FaqAccordion.displayName = "FaqAccordion";
