import { BoxProps } from "@ui/components/Box/Box";
import { FlexBox } from "@ui/components/FlexBox/FlexBox";
import { Icon } from "@ui/components/Icon/Icon";
import { BodyText } from "@ui/components/Typography/BodyText";
import { COLORS } from "@ui/theme/colors";
import { TYPOGRAPHY } from "@ui/theme/typography";
import { CLASSES } from "@uxf/core/constants/classes";
import { cx } from "@uxf/core/utils/cx";
import { CSSProperties, FC, MouseEvent, PropsWithChildren, ReactNode, useCallback } from "react";
import { Collapsible, CollapsibleProps } from "./Collapsible";
import { collapsibleFaqRootStyle } from "./styles/CollapsibleFaqRoot";
import { chevronStyle } from "./styles/chevronStyle";

const DEFAULT_COLORS = {
    title: COLORS.green.default,
    text: COLORS.green.default,
    border: COLORS.green.default,
    focus: COLORS.green.default,
};

const INVERT_COLORS = {
    title: COLORS.yellow.default,
    text: COLORS.white,
    border: COLORS.white,
    focus: COLORS.white,
};

export interface CollapsibleFaqProps extends CollapsibleProps, Omit<BoxProps, "title"> {
    headingElement?: keyof Pick<JSX.IntrinsicElements, "h2" | "h3" | "h4" | "h5" | "h6" | "p">;
    invert?: boolean;
    text?: ReactNode;
    title: ReactNode;
}

export const CollapsibleFaq: FC<PropsWithChildren<CollapsibleFaqProps>> = (props) => {
    const { $css, headingElement = "p", invert, isExpanded, onClick, onToggle, title, text, ...restProps } = props;

    const colors = invert ? INVERT_COLORS : DEFAULT_COLORS;

    const allowTextSelection = useCallback(
        (e: MouseEvent<HTMLDivElement | HTMLParagraphElement>) => e.stopPropagation(),
        [],
    );

    return (
        <Collapsible
            $css={collapsibleFaqRootStyle(colors.border, $css)}
            disableScrollOnExpanded={!text}
            focusColor={colors.focus}
            isExpanded={isExpanded}
            onClick={onClick}
            onToggle={onToggle}
            $mt={-1}
            {...restProps}
        >
            <FlexBox $minHeight={60}>
                <FlexBox $alignItems="center" $flexGrow={1} $py={8}>
                    <BodyText as={headingElement} $color={colors.title} $fontWeight={TYPOGRAPHY.fontWeight.bold}>
                        {title}
                    </BodyText>
                </FlexBox>
                <FlexBox $alignItems="center" $width={36} $flexShrink={0} $mx={12}>
                    <Icon
                        $css={chevronStyle}
                        $width={36}
                        className={isExpanded ? CLASSES.IS_ACTIVE : undefined}
                        color={COLORS.green.default}
                        name={invert ? "arrowRightCircleFilledYellow" : "arrowRightCircleFilled"}
                    />
                </FlexBox>
            </FlexBox>
            {text && (
                // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
                <div
                    className={cx(
                        !isExpanded && "sr-only",
                        "mb-4 cursor-default text-[--faq-accordion-text-color] max-lg:text-12",
                    )}
                    style={{ "--faq-accordion-text-color": colors.text } as CSSProperties}
                    onClick={allowTextSelection}
                >
                    {text}
                </div>
            )}
        </Collapsible>
    );
};
