import { injectCss } from "@uxf/styles/mixins/injectCss";
import { ColorProperty } from "@uxf/styles/types";
import { css, FlattenSimpleInterpolation } from "styled-components";

export const collapsibleFaqRootStyle = ($borderColor?: ColorProperty, $css?: FlattenSimpleInterpolation) => css`
    border-bottom: 1px solid ${$borderColor};
    border-top: 1px solid ${$borderColor};
    padding: 4px 0;

    ${injectCss($css)};
`;
